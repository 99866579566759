import * as React from "react"
import classnames from "classnames"

import { HeroProps } from "../../interfaces/HeroProps"
import SectionTitle from "../molecules/sectionTitle"

const Heading: React.FC<HeroProps> = ({ data, className }) => {
  const { title, subtitle } = data
  if (data) {
    return (
      <div className={classnames("relative flex items-center", className)}>
        <div className="container my-m lg:my-l pt-l lg:pt-xl text-center z-10">
          <div className="max-w-3xl mx-auto">
            <SectionTitle title={title} subtitle={subtitle} />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Heading
