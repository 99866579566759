import * as React from "react"
import classnames from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { SectionTitleProps } from "../../interfaces/SectionTitleProps"
import Paragraph from "../atoms/paragraph"
import Title from "../atoms/title"

const SectionTitle: React.FC<SectionTitleProps> = props => {
  const { className, title, subtitle, subtitleImg } = props
  const SubtitleImg = subtitleImg && getImage(subtitleImg.localFile)

  return (
    <div className={classnames(className)}>
      <Title as="h2" className="mb-xs">
        {title}
      </Title>
      {subtitle && <Paragraph>{subtitle}</Paragraph>}
      {SubtitleImg && (
        <GatsbyImage
          className="-mt-m inline-block"
          image={SubtitleImg}
          alt={subtitleImg?.altText || ""}
        />
      )}
      <div className="block">
        <div className="bg-secondary h-2 w-8 mx-m my-s lg:my-m inline-block" />
      </div>
    </div>
  )
}

export default SectionTitle
