import * as React from "react"
import { PageProps, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Seo from "gatsby-plugin-wpgraphql-seo"

import transform from "../../utils/htmlParser"
import Layout from "../layout"
import Heading from "../organisms/heading"

type DataProps = {
  wpPage: {
    seo: any
    hero_section: any
    content: any
  }
}

const Default: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { hero_section, content } = data.wpPage

  return (
    <Layout>
      <Seo post={data.wpPage} />
      <Heading data={hero_section} />
      <div className="container mb-l">
        {content &&
          ReactHtmlParser(content, {
            decodeEntities: true,
            transform,
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DefaultPageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      ...heroPageFields
      ...seoPagesFields
      content
    }
  }
`

export default Default
